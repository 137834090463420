/* Table of contents
––––––––––––––––––––––––––––––––––––––––––––––––––
- Title
- Login
- Intro
- Letter
- Program
- Gallery
- Accommodation
- Registration
*/

/* Title
–––––––––––––––––––––––––––––––––––––––––––––––––– */
#title, #login {
	padding: 12% 10%;

	@include media-breakpoint-up(md) {
		padding: 16%;
	}

	@include media-breakpoint-up(lg) {
		padding: 18% 10%;
	}

	h1 {
		text-align: center;
		max-width: 815px;

		@include media-breakpoint-up(lg) {
			font-size: 3.75rem;
		}

	}

	.flower-1 {
		left: 0;
		top: 0;
		margin: -1rem;
		max-width: 35vw;

		@include media-breakpoint-up(md) {
			max-width: 20vw;
		}

		@include media-breakpoint-up(lg) {
			margin: -3.2rem;
		}

	}

	.flower-2 {
		right: 0;
		top: 0;
		margin: -1rem;
		max-width: 35vw;

		@include media-breakpoint-up(md) {
			max-width: 20vw;
		}

		@include media-breakpoint-up(lg) {
			margin: -3.2rem;
		}

	}

}

/* Login
–––––––––––––––––––––––––––––––––––––––––––––––––– */
#login {
	
}


/* Intro
–––––––––––––––––––––––––––––––––––––––––––––––––– */
#intro {

	.photo {
		background: #fff;
		padding: 1.5rem 1.5rem 1rem 1.5rem;
		transform: rotate(352deg);
		margin-top: 1rem;
		margin-bottom: 1rem;

		.title {
			text-align: center;
			margin-top: 10px;
			margin-bottom: 0;
		}

	}

	.flower-3 {
		left: 0;
		margin: -1rem;
		max-width: 35vw;

		@include media-breakpoint-up(md) {
			max-width: 20vw;
		}

		@include media-breakpoint-up(lg) {
			margin: -3.2rem;
		}

	}

	.flower-4 {
		right: 0;
		margin: -1rem;
		max-width: 35vw;
		transform: translateY(60px);

		@include media-breakpoint-up(md) {
			max-width: 20vw;
			transform: translateY(200px);
		}

		@include media-breakpoint-up(lg) {
			margin: -3.2rem;
		}

	}

}

/* Letter
–––––––––––––––––––––––––––––––––––––––––––––––––– */
#letter {

	.envelope {
		width: 100%;
		position: relative;

		.envelope-back {
			bottom: 0;
			position: absolute;
			width: 100%;
			z-index: 1;
			transform: translateY(-25%);
		}

		.letter {
			padding: 1rem;
			max-width: 90%;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: -90px;
			z-index: 2;
			position: relative;
			background: rgb(255,255,250);
			background: -moz-linear-gradient(top, rgba(255,255,250,1) 0%, rgba(238,240,230,1) 100%);
			background: -webkit-linear-gradient(top, rgba(255,255,250,1) 0%,rgba(238,240,230,1) 100%);
			background: linear-gradient(to bottom, rgba(255,255,250,1) 0%,rgba(238,240,230,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fffffa', endColorstr='#eef0e6',GradientType=0 );

			@include media-breakpoint-up(md) {
				padding: 2rem;
				max-width: 94%;
				margin-bottom: -180px;
			}

			@include media-breakpoint-up(lg) {
				margin-bottom: -220px;
			}

			@include media-breakpoint-up(xl) {
				margin-bottom: -280px;
			}

		}

		.letter-inner {
			padding-bottom: 70px;

			@include media-breakpoint-up(md) {
				padding: 2rem;
				border: 2px solid #C1BCB7;
				padding-bottom: 140px;
			}

			@include media-breakpoint-up(lg) {
				padding-bottom: 180px;
			}

			@include media-breakpoint-up(xl) {
				padding-bottom: 240px;
			}

		}

		.envelope-front {
			z-index: 3;
			position: relative;
		}

	}

	.flower-5 {
		right: 0;
		margin: -1rem;
		max-width: 35vw;
		transform: translateX(35%) translateY(260px);
		z-index: 3;

		@include media-breakpoint-up(md) {
			max-width: 30vw;
		}

		@include media-breakpoint-up(lg) {
			margin: -3.2rem;
		}

	}

}

/* Program
–––––––––––––––––––––––––––––––––––––––––––––––––– */
#program {

	h2 {
		margin-top: 1rem;
	}

	.flower-6 {
		left: 0;
		margin: -1rem;
		max-width: 35vw;
		bottom: 0;
		transform: translateX(-35%) translateY(-260px);

		@include media-breakpoint-up(md) {
			max-width: 25vw;
			transform: translateX(-40%) translateY(-160px);
		}

		@include media-breakpoint-up(lg) {
			margin: -3.2rem;
		}

	}

	.flower-7 {
		right: 0;
		margin: -1rem;
		max-width: 35vw;
		transform: translateX(35%) translateY(240px);

		@include media-breakpoint-up(md) {
			max-width: 25vw;
			transform: translateX(35%) translateY(200px);
		}

		@include media-breakpoint-up(lg) {
			margin: -3.2rem;
		}

	}

}

/* Gallery
–––––––––––––––––––––––––––––––––––––––––––––––––– */
#gallery {

	@include media-breakpoint-up(md) {
		padding-bottom: 3rem;
	}

}

/* Accommodation
–––––––––––––––––––––––––––––––––––––––––––––––––– */
#accommodation {

	.hotel-list {
		margin: 3rem 0;

		> li {
			margin-bottom: 2rem;
		}

	}

	.mountains-1 {
		right: 0;
		margin: -1rem;
		max-width: 80vw;
		transform: translateX(30%) translateY(120px);

		@include media-breakpoint-up(md) {
			transform: translateX(50%) translateY(120px);
		}

		@include media-breakpoint-up(lg) {
			margin: -3.2rem;
		}

	}

	.mountains-2 {
		left: 0;
		margin: -1rem;
		max-width: 80vw;
		transform: translateX(-40%) translateY(760px);

		@include media-breakpoint-up(md) {
			transform: translateX(-60%) translateY(440px);
		}

		@include media-breakpoint-up(lg) {
			margin: -3.2rem;
			transform: translateX(-40%) translateY(580px);
		}

	}

}

/* Registration
–––––––––––––––––––––––––––––––––––––––––––––––––– */
#registration {
	padding-bottom: 1rem;

	.section-title {
		text-align: center;
	}

	.form-row {

		&.arrive {
			margin-bottom: 2rem;
		}

	}

	.flower-9 {
		left: 0;
		margin: -1rem;
		max-width: 35vw;
		bottom: 0;

		@include media-breakpoint-up(md) {
			max-width: 20vw;
		}

		@include media-breakpoint-up(lg) {
			margin: -3.2rem;
		}

	}

	.flower-10 {
		right: 0;
		bottom: 0;
		margin: -1rem;
		max-width: 35vw;

		@include media-breakpoint-up(md) {
			max-width: 20vw;
		}

		@include media-breakpoint-up(lg) {
			margin: -3.2rem;
		}

	}

}

