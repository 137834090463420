// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brown: #744719;

$theme-colors: (
  "primary": #744719
);

// Body
$body-bg: #EAE9DF;
$body-color: #744719;

// Buttons
$btn-border-radius:	32px;
$btn-border-radius-sm: 32px;
$btn-border-radius-lg: 32px;

// Links
$link-hover-decoration: none;

// Forms
$input-bg: transparent;
$input-border-color: #D1CAB7;
$input-border-radius: 0;
$input-border-radius-lg: 0;
$input-border-radius-sm: 0;
$input-focus-box-shadow: none;
$input-focus-border-color: $brown;


// Sprite
@mixin cssSprite( $spriteVals ) {
	width: nth( $spriteVals, 1 );
	height: nth( $spriteVals, 2 );
	background-repeat: no-repeat;
	background-image: url( #{ nth( $spriteVals, 3 ) } );
	background-position: nth( $spriteVals, 4 ) nth( $spriteVals, 5 );
	@media only screen and ( -webkit-min-device-pixel-ratio: 2 ), only screen and ( min-device-pixel-ratio: 2 ) {
		background-image: url( #{ nth( $spriteVals, 6 ) } );
		background-size: $bgiSizeW $bgiSizeH;
	}
}
$spritePath: '../images/sprite';
$spriteURL: $spritePath + '.png';
$spritex2URL: $spritePath + '@2x.png';
$bgiSizeW: 2551px;
$bgiSizeH: 1592px;
$image-10: 302px 313px $spriteURL -2249px -422px $spritex2URL;
$image-9: 379px 471px $spriteURL -1795px -267px $spritex2URL;
$image-8: 1305px 742px $spriteURL -851px -850px $spritex2URL;
$image-7: 312px 309px $spriteURL 0px -815px $spritex2URL;
$image-6: 416px 548px $spriteURL -365px -850px $spritex2URL;
$image-5: 397px 434px $spriteURL -1323px -205px $spritex2URL;
$image-4: 176px 424px $spriteURL -1097px -210px $spritex2URL;
$image-3: 266px 641px $spriteURL -781px 0px $spritex2URL;
$image-2: 379px 471px $spriteURL -352px -163px $spritex2URL;
$image-1: 302px 313px $spriteURL 0px -321px $spritex2URL;
$section-spacer: 1565px 64px $spriteURL 0px -674px $spritex2URL;
$spriteList: $image-10 image-10, $image-9 image-9, $image-8 image-8, $image-7 image-7, $image-6 image-6, $image-5 image-5, $image-4 image-4, $image-3 image-3, $image-2 image-2, $image-1 image-1, $section-spacer section-spacer;
@each $icon in $spriteList {
	$spriteName: nth( $icon, 2 );
	$spriteClass: nth( $icon, 1 );
	.icon-#{$spriteName} {
		@include cssSprite( $spriteClass );
	}
}
