// Grid system
.main {
  @include media-breakpoint-up(sm) {
    @include make-col($main-sm-columns);
  }
}

// Custom Container
$container-big-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px
);

@mixin make-max-widths-container-big-width($max-widths: $container-big-max-widths, $breakpoints: $grid-breakpoints) {
    @each $breakpoint, $container-big-max-width in $max-widths {
        @include media-breakpoint-up($breakpoint, $breakpoints) {
            max-width: $container-big-max-width;
        }
    }
}

.container-big {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
   @include make-max-widths-container-big-width();
}