// Button
.btn {
	font-family: Sacramento;
	color: $body-color;
	font-size: 1.6em;
	padding: .375rem 3.5rem .1rem;

	&:hover, &:focus {
		color: $body-color;
	}

}