/* Table of contents
––––––––––––––––––––––––––––––––––––––––––––––––––
- Font Faces
- Base Styles
- Typography
- Links
- Utilities
- Forms
- Lists
- Tables
- Misc
- Clearing
*/


/* Font Faces
–––––––––––––––––––––––––––––––––––––––––––––––––– */

//== Sacramento
//
@font-face {
    font-family: 'Sacramento';
    src: url('../fonts/Sacramento-Regular.eot');
    src: url('../fonts/Sacramento-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Sacramento-Regular.woff2') format('woff2'),
        url('../fonts/Sacramento-Regular.woff') format('woff'),
        url('../fonts/Sacramento-Regular.ttf') format('truetype'),
        url('../fonts/Sacramento-Regular.svg#Sacramento-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

//== Cormorant Garamond
//
@font-face {
    font-family: 'Cormorant Garamond';
    src: url('../fonts/CormorantGaramond-Regular.eot');
    src: url('../fonts/CormorantGaramond-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CormorantGaramond-Regular.woff2') format('woff2'),
        url('../fonts/CormorantGaramond-Regular.woff') format('woff'),
        url('../fonts/CormorantGaramond-Regular.ttf') format('truetype'),
        url('../fonts/CormorantGaramond-Regular.svg#CormorantGaramond-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

/* Base Styles
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.wrap {

	&.login {
		height: calc(100vh - (1rem * 2));

		@include media-breakpoint-up(lg) {
			height: calc(100vh - (3.2rem * 2));
		}

		.content {
			height: 100%;
		}

	}

}

.content {
	border: 2px solid #D1CAB7;
	margin: 1rem;

	@include media-breakpoint-up(lg) {
		margin: 3.2rem;
	}

}

section {
	position: relative;
	padding: 2rem 0 3.5rem 0;

	@include media-breakpoint-up(md) {
		padding: 4rem 0 7rem 0;
	}
	
	@include media-breakpoint-up(lg) {
		padding: 6rem;
	}

	&:not(:last-of-type):after {
		position: absolute;
		content: "";
		left: 0;
		bottom: 0;
		background: url("../images/spacer.png");
		background-repeat: repeat-x;
		background-size: contain;
		height: 20px;
		width: 100%;

		@include media-breakpoint-up(md) {
			height: 24px;
		}

		@include media-breakpoint-up(lg) {
			height: 36px;
		}

	}

}

.element {
	position: absolute;

	img {
		width: 100%;
	}

}

/* Typography
–––––––––––––––––––––––––––––––––––––––––––––––––– */

body {
	font-family: "Cormorant Garamond";
	overflow-x: hidden;

	@include media-breakpoint-up(lg) {
		font-size: 1.31rem;
	}

}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
	font-family: "Sacramento";
	margin-bottom: .8rem;
}

h1 {
	@include media-breakpoint-up(lg) {
		font-size: 3.75rem;
	}
}

h2 {
	@include media-breakpoint-up(lg) {
		font-size: 3.13rem;
	}
}

/* Links
–––––––––––––––––––––––––––––––––––––––––––––––––– */


/* Utilities
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.full-width {
	width: 100vw;
	position: relative;
  	left: 50%;
  	right: 50%;
  	margin-left: -50vw;
  	margin-right: -50vw;

  	img.img-full {
  		width: 100%;
	  	height: auto;
	  	vertical-align: bottom; 
  	}

}

/* Forms
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.form-section {
	margin-bottom: 2rem;
}

.form-control {
	border-top: none;
	border-left: none;
	border-right: none;
}

.checkmark {
    position: absolute;
    top: -2px;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1px solid #D1CAB7;
    background-color: transparent;

    @include media-breakpoint-up(lg) {
    	top: 2px;
    }

}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.form-check {
	display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 1rem;
    cursor: pointer;
    user-select: none;
}

.form-check input[type='checkbox'] {
	position: absolute;
    opacity: 0;
    cursor: pointer;
}

#staticPickupNumber {
	width: 40px;
	padding-top: 0;
	padding-bottom: 0;
	display: inline;
	height: 20px;
	padding-right: 0;
}

.form-check:hover input ~ .checkmark {
    background-color: #e1dfd0;
}

.form-check input:checked ~ .checkmark {
    background-color: #D1CAB7;
}

.form-check input:checked ~ .checkmark:after {
    display: block;
}

.form-check .checkmark:after {
    left: 8px;
    top: 4px;
    width: 7px;
    height: 14px;
    border: solid $brown;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* Lists
–––––––––––––––––––––––––––––––––––––––––––––––––– */


/* Tables
–––––––––––––––––––––––––––––––––––––––––––––––––– */


/* Misc
–––––––––––––––––––––––––––––––––––––––––––––––––– */


/* Clearing
–––––––––––––––––––––––––––––––––––––––––––––––––– */